import React from 'react';
import { useField, useFormikContext } from 'formik';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import {Box, Typography} from '@mui/material';

import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';


import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/de';
import dayjs from 'dayjs';

const Terminierung = () => {
    const { setFieldValue, values } = useFormikContext();
    const [date1Field] = useField('termin1');
    const [date2Field] = useField('termin2');
    const [date3Field] = useField('termin3');
    const [dringlichkeitDAYS] = useField('dringlichkeitDAYS');

    const handleDateChange = (date, field) => {
        setFieldValue(field, dayjs(date).locale('de').format('DD.MM.YYYY - HH:mm:ss'));
    };
    
    // const futureDate = dayjs(new Date()).add(dringlichkeitDAYS.value, 'day');
    //  // Define minimum and maximum times as Date objects
     const minTime = dayjs().set('hour', 8).set('minute', 0);;
     const maxTime = dayjs().set('hour', 17).set('minute', 59);

    const futureDate = (() => {
        const calculatedDate = dayjs(new Date()).add(dringlichkeitDAYS.value, 'day');
        const minimumDate = dayjs('2025-01-07'); // 7. Januar 2025
        return calculatedDate.isBefore(minimumDate) ? minimumDate : calculatedDate;
    })();
 

    const isWeekend = (date) => {
        const day = dayjs(date).day();
        return day === 0 || day === 6; // Sunday (0) or Saturday (6)
    };

    const shouldDisableDate = (date) => {
        return isWeekend(date);
    };

    return (
        <div>
        <h2>Terminierung</h2>
        <Grid container spacing={2} mt={2}>
            <Grid item xs={12} md={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                    <MobileDateTimePicker  
                        sx={{"border": "1px solid lightgrey"}} 
                        {...date1Field}
                        // value={values.termin1 ? dayjs(values.termin1).toDate() : null}
                        name="termin1" 
                        label="Terminvorschlag 1"    
                        minDate={futureDate}
                        minutesStep={15}
                        minTime={minTime}
                        maxTime={maxTime}
                        shouldDisableDate={shouldDisableDate} // Disable weekends
                        onChange={(date) => handleDateChange(date, 'termin1')}
                    />
                </LocalizationProvider> 
            </Grid>
            <Grid item xs={12} md={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                    <MobileDateTimePicker 
                        sx={{"border": "1px solid lightgrey"}} 
                        {...date2Field}  
                        // value={values.termin2 ? dayjs(values.termin2).toDate() : null}
                        name="termin2" 
                        label="Terminvorschlag 2"
                        minDate={futureDate}
                        minutesStep={15}
                        minTime={minTime}
                        maxTime={maxTime}
                        shouldDisableDate={shouldDisableDate} // Disable weekends
                        onChange={(date) => handleDateChange(date, 'termin2')}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                    <MobileDateTimePicker
                        sx={{"border": "1px solid lightgrey"}} 
                        {...date3Field}
                        // value={values.termin3 ? dayjs(values.termin3).toDate() : null}
                        name="termin3" 
                        label="Terminvorschlag 3"
                        minDate={futureDate}
                        minutesStep={15}
                        minTime={minTime}
                        maxTime={maxTime}
                        shouldDisableDate={shouldDisableDate} // Disable weekends
                        onChange={(date) => handleDateChange(date, 'termin3')}
                    />
                </LocalizationProvider>
            </Grid>
        </ Grid>
    </div>
    );
};

export default Terminierung;